.wppButtonContainer {
    background-color: #4ac959;
    position: fixed;
    bottom: 6rem;
    right: 2rem;
    border-radius: 50%;
    box-shadow: 1px 1px 20px 0px black;
    animation: shine 3s infinite ease-out;
    z-index: 1000;
}

.wppButtonContainer:hover {
    transform: scale(1.1);
    transition: .3s;
}

.wppButtonContainer img {
    width: 2rem;
    margin: 1rem;
}

@keyframes shine {
    from {
        box-shadow: 1px 1px 20px 0px black;
    }

    50% {
        box-shadow: none;
    }

    to {
        box-shadow: 1px 1px 20px 0px black;
    }
}

@media(max-width: 991px) {
    .wppButtonContainer{
        bottom: 2rem;
    }
}