.top_header{
    padding: 50px 30px 30px 30px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #ffffff;
}

.slogan_header{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    background: #87B338;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
}

.top_header, .space_header{
    height: 9rem;
}

.top_header_clean, .space_header_clean{
    height: 7rem;
}

.top_header_clean{
    padding: 30px;
}

.space_header{
    width: 100%;
}

.home-navbar {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    height: 100%;
}

.home-nav {
    gap: 30px;
    color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.home-nav1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.home-nav2 {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.header-title, .nav_list span{
    color: #7e0d10;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    text-wrap: nowrap;
}

.home-desktop-menu {
    display: flex;
    /* position: relative;
    top: .5rem; */
    padding: 0 15px;
}

.home-image-logo {
    width: 200px;
    object-fit: cover;
}

.home-logo1 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.subscribe_button{
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    padding: 16px 24px;
    background-color: rgb(255, 207, 119);
    border-radius: 15px;
    z-index: 10;
    text-decoration: none;
    color: #000000;
}

.subscribe_button:hover{
    text-decoration: none;
    color: #000000;
    background-color: rgba(255, 207, 119, 0.735);
}

.header_img{
    width: 30px;
}

.nav_list{
    position: relative;
}

.nav_list .text_container{
    display: flex;
    gap: 5px;
    cursor: pointer;
    user-select: none;
    color: #7e0d10;
    align-items: center;
}

.nav_list .nav_list_items{
    position: absolute;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 5px;
    z-index: 10;
    width: 100%;
    box-shadow: 0px 5px 10px 0px #000000;;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.search_mobile{
    display: none;
}

.searchProductForm input{
    border-right: none!important;
}

.searchProductForm input,
.searchProductForm span,
.searchProductForm input:focus{
    color: #7e0d10;
    border: 1px solid #7e0d10;
    box-shadow: none;
}

.searchProductForm input::placeholder{
    color: #bf5555;
}

.searchProductForm input::-webkit-search-decoration,
.searchProductForm input::-webkit-search-cancel-button,
.searchProductForm input::-webkit-search-results-button,
.searchProductForm input::-webkit-search-results-decoration {
  display: none; 
}

@media(max-width: 1200px) {
    .header-title, .nav_list {
        display: none;
    }

    .img_force_display{
        display: block!important;
    }

    .home-logo1 {
        color: #000000;
    }
    
    .header_img{
        width: 25px;
    }

    .home-button04 {
        display: flex;
        padding: var(--dl-space-space-unit);
        border-radius: 15px;
    }

    .home-burger-menu {
        display: flex!important;
    }

    .subscribe_button{
        display: none;
    }

    .home-image-logo{
        width: 150px;
    }
}

@media(max-width: 767px) {
   
    .search_desktop{
        display: none;
    }

    .search_mobile{
        display: block;
        margin: 15px 0;
    }

    .top_header, .space_header{
        height: 12rem;
    }

    .home-navbar{
        height: fit-content;
    }

    .top_header_clean, .space_header_clean{
        height: 7rem;
    }
}

@media(max-width: 479px) {
    .top_header {
        padding-top: 60px;
    }
    .slogan_header{
        font-size: 14px;
    }
    .top_header_clean{
        padding-top: 30px;
    }
}