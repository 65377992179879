.collage {
    gap: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 30px;
    flex-direction: column;
}

.collage .subcontainer{
    color: #ffffff;
    gap: 1rem;
}

.collage h1 {
    font-size: 30px;
    color: #ffffff !important;
    margin: 0;
    padding: 0;
    font-weight: bold;
}

.collage span {
    font-size: 50px;
}

.collage ul{
    margin: 0;
}

.collage ul li {
    list-style: none;
    font-size: 18px;
    line-height: 1;
}

.collage_container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.gallery {
    width: 100%;
    height: 1200px;
    display: grid;
    position: relative;
    align-items: flex-start;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "a b b" "a b b" "a c d" "a c d" "e e d" "e e f" "e e f";
    grid-template-columns: 1fr 1fr 1fr;
}

.gallery a{
    text-decoration: none;
}

.hogar {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 1;
    grid-area: a;
    transition: 0.3s;
    align-items: flex-end;
    flex-direction: column;
    background-size: cover;
    background-image: url('../images/collage/home.jpeg');
    background-position: center;
    position: relative;
    overflow: hidden;
}

.hogar:hover {
    transform: scale(0.98);
}

.hogar_container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    flex-direction: column;
    color: #ffffff;
}

.hogar span{
    font-size: 30px;
}

.collage_more_info{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgb(0 0 0 / 90%);
    width: 100%;
    height: 100%;
    padding: 0 30px;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    font-size: 20px;
    display: none;
    text-align: center;
}

.collage_more_info ul{
    margin: 0;
    padding: 0;
    text-align: left;
}
.collage_more_info ul li{
    list-style: inside;
    font-size: 20px;
    line-height: normal;
}

.hogar:hover > .collage_more_info,
.promo:hover > .collage_more_info,
.serv_finan:hover > .collage_more_info,
.salud:hover > .collage_more_info{
    display: flex;
}

.conocenos {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: b;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-image: url('../images/collage/conocenos.png');
    background-size: cover;
    background-position-y: 6rem;
    background-repeat: no-repeat;
}

.conocenos:hover {
    transform: scale(0.98);
}

.conocenos_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 16px;
    border-width: 1px;
    border-radius: 4px;
    flex-direction: column;
    justify-content: space-between;
}

.conocenos_container ul li, .conocenos_container h1, .conocenos_container span{
    color: #7e0d10!important;
}

.serv_finan {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: c;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('../images/collage/gasolina.jpeg');
    background-position: center;
}

.serv_finan:hover {
    transform: scale(0.98);
}

.serv_fin_container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 32px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

.salud {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: d;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: contain;
    background-image: url('../images/collage/salud.jpeg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.salud:hover {
    transform: scale(0.98);
}

.salud_container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    flex-direction: column;
}

.promo {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: e;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('../images/collage/promo.jpeg');
    background-position: center;
}

.promo:hover {
    transform: scale(0.98);
}

.promo_container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    flex-direction: column;
}

.promo span{
    font-size: 30px;
}

.promo p{
    font-size: 20px;
    margin: 0;
}

.tu_colonia {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: f;
    transition: 0.3s;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('../images/collage/tu_colonia.jpeg');
    background-position: center;
}

.tu_colonia:hover {
    transform: scale(0.98);
}

.tu_colonia_container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    flex-direction: column;
}

.button_collage {
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    padding: 16px 24px;
    background-color: rgb(255, 207, 119);
    border-radius: 15px;
}

@media(max-width: 991px) {
    .collage{
        padding: 0 15px;
    }

    .gallery {
        grid-gap: 16px;
    }

    .button_collage {
        padding: 16px;
    }
}

@media(max-width: 767px) {
    .collage {
        gap: 48px;
    }

    .gallery {
        height: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px;
    }

    .conocenos_container {
        height: 40vh;
    }

    .serv_finan {
        border-width: 1px;
    }

    .salud {
        height: 264px;
        border-width: 1px;
        border-radius: 4px;
        background-size: cover;
        background-image: url("../images/collage/salud_movil.jpeg");
        background-position: center;
    }

    .salud_container ul,
    .serv_fin_container ul,
    .hogar_container ul{
        padding: 0;
    }

    .tu_colonia {
        height: 45vh;
        background-size: cover;
        background-image: url('../images/collage/tu_colonia_movil.jpeg');
    }

    .serv_fin_container{
        padding: 16px;
    }
    .serv_fin_container h1,
    .promo_container h1,
    .promo_container span,
    .hogar_container span{
        font-size: 25px;
    }

    .hogar_container,
    .promo_container,
    .tu_colonia_container{
        background: rgb(0 0 0 / 50%);
    }
}

@media(max-width: 479px) {}