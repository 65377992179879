.container_login_register{
    background-color: #D1FFE3;
    width: 100%;
    margin: 0;
    max-width: unset!important;
    padding: 3rem 0 15rem 0;
    background-image: url('../images/registrar.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

.login_form{
    margin: 25px 0 0 0;
}

.login_title{
    font-size: 3rem;
    text-align: center;
    color: #7e0d10 !important;
}

.login_form label{
    color: #000000!important;
    font-weight: 500;
    margin: 1rem 0!important;
}

.login_button{
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    padding: 13px 20px;
    background-color: #9DDADB;
    width: fit-content;
    display: flex;
    margin: 0 auto;
    color: #000000!important;
}

.register_redirect_button{
    box-shadow: 5px 6px 0px 0px #000000;
    border-width: 1px;
    padding: 13px 20px;
    background-color: #7e0d10;
    width: fit-content;
    display: flex;
    margin: 15px auto 0 auto;
    color: #ffffff!important;
    text-decoration: none;
    border-radius: 6px;
}

.register_redirect_button:hover{
    background-color: #7e0d11a5;
}

.login_span{
    color: #000000;
    font-weight: 500;
}

.login_link{
    color: #7e0d10!important;
}

.password_reset_container{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: .5rem;
}

@media(max-width: 991px) {}

@media(max-width: 767px) {
    .container_login_register{
        padding: 0 2rem 15rem 2rem;
    }
}

@media(max-width: 479px) {
    .password_reset_container{
        flex-direction: column;
        text-align: center;
    }
}
