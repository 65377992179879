.social_floating_container{
    position: fixed;
    left: 0;
    top: 80%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.social_floating_container a{
    text-decoration: none;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0 15px;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    max-height: 50px;
    position: relative;
    left: -105px;
    transition: .3s;
}

.social_floating_container a:hover{
    left: 0;
}

.social_floating_container img{
    filter: brightness(0) invert(1);
}

.social_floating_container .twitter_container{
    background-color: #1d9bf0;
}
.social_floating_container .twitter_container img{
    width: 40px;
}

.social_floating_container .insta_container{
    background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
}
.social_floating_container .insta_container img{
    width: 30px;
}

.social_floating_container .fb_container{
    background-color: #4267b2;
}
.social_floating_container .fb_container img{
    width: 30px;
}

.social_floating_container .yt_container{
    background-color: #FF0000;
}
.social_floating_container .yt_container img{
    width: 30px;
    position: relative;
    top: 3px;
}
