.slider_home{
    margin-bottom: 30px;
}

.slider_home img{
    width: 100%;
    height: 35rem;
}

.slider_home h3{
    color: #ffffff;
}

@media(max-width: 991px) {
    .slider_home img{
        height: 25rem;
    }
    
}



  
  
  
  