.footer {
    width: 100%;
    margin: 30px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
}

.footer a{
    text-decoration: none;
    color: #888888;
    font-size: 18px;
    cursor: pointer;
}

.footer a:hover{
    text-decoration: underline;
}

.footer_logo {
    width: 15rem;
}

.text_container {
    display: flex;
    gap: 1rem;
}

.social {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    border-top: 1.5px solid rgb(233, 233, 233);
    padding-top: 30px;
}

.social_img_container {
    display: flex;
    gap: 3rem;
}

.social_img_container img {
    width: 20px;
}

@media(max-width: 991px) {
    .social {
        flex-direction: column;
        gap: 2rem;
    }

    .social_img_container {
        gap: 2rem;
    }

    .social_img_container img {
        width: 30px;
    }
}

@media(max-width: 767px) {
    .text_container{
        flex-direction: column;
        align-items: center;
    }
}