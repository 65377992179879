.nav_mobile_container{
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    right: -100%;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    padding: 1rem;
}

.slideIn{
    animation: slideIn .5s ease-in;
    right: 0;
}

@keyframes slideIn {
    from {
        right: -100%;
    }

    to {        
        right: 0;
    }
}

.slideOut{
    animation: slideOut .5s ease-in;
    right: -100%;
}

@keyframes slideOut {
    from {
        right: 0;
    }

    to {        
        right: -100%;
    }
}

.header_nav_mobile{
    display: flex;
    justify-content: right;
}

.header_nav_mobile svg{
    width: 50px;
    height: 50px;
}

.list_nav_mobile{
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.list_nav_mobile a{
    text-decoration: underline!important;
    color: #ffffff!important;
    font-size: 1.5rem;    
    text-transform: uppercase;
    text-wrap: nowrap;
}

@media(min-width: 1200px) {
    .nav_mobile_container{
        right: -100%;
    }
}

@media(max-width: 991px) {
    .nav_mobile_container{
        padding: 53px 26px;
    }
}

@media (max-width: 767px){
    .nav_mobile_container {
        padding: 54px 28px;
    }
}

@media(max-width: 479px) {
    .nav_mobile_container {
        padding: 36px 16px;
    }
}
