.nosotros_container {
    width: 100%;
    display: flex;
    margin-top: 50px;
    padding: 0 30px;
}

.texto_container {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.texto_container h1 {
    font-size: 3rem;
    color: #87B338;
    line-height: 1;
}

.texto_container p {
    font-size: 1.2rem;
}

.texto_container iframe{
    height: 100%;
}

.imagen_container {
    width: 50%;
}

.imagen_container img {
    width: 80%;
    margin: 0 auto;
    display: block;
}

@media(max-width: 991px) {
    .nosotros_container {
        flex-direction: column;
        margin-top: 25px;
        padding: 0 15px;
    }

    .texto_container,
    .imagen_container {
        width: 100%;
    }

    .texto_container h1 {
        width: 100%;
        text-align: center;
    }

    .texto_container iframe{
        height: 25rem;
    }
}

@media(max-width: 767px) {
    .texto_container h1 {
        font-size: 2rem;
    }

    .texto_container iframe{
        height: 20rem;
    }
}

@media(max-width: 479px) {
    .texto_container iframe{
        height: 15rem;
    }
}
